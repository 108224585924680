import { ActionEvent, Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal"]
  declare modalTarget : HTMLElement

  verifyAge(e: ActionEvent) : void {
    e.preventDefault();

    const metaElement = document.querySelector("meta[name='csrf-token']") as HTMLMetaElement | null;
    const csrfToken = metaElement?.content || "";

    this.modalTarget.classList.remove("is-active");

    fetch('/age_verification', {
      method: "POST",
      credentials: "include",
      headers: { "X-CSRF-Token": csrfToken },
    });
  }
}
