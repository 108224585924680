import React from "react"
import { createRoot } from "react-dom/client";

import { Controller } from "@hotwired/stimulus";
import { liteClient as algoliasearch, LiteClient } from "algoliasearch/lite";

import Autocomplete from "src/components/autocomplete";
import StoresAutocompleteConfig from "src/components/autocomplete/stores_autocomplete_config";
import searchInsights from "search-insights";

export default class AlgoliaAutocomplete extends Controller {
  static values = {
    algoliaAppId: String,
    algoliaPublicApiKey: String,
    algoliaIndexName: String,
    algoliaIndexType: String,
    initialQuery: String,
    storeIds: Array<number>,
    excludeAgeRestrictedStores: Boolean,
    imageBaseUrl: String,
    customerUserToken: String,
    customerAuthenticatedUserToken: String,
  }

  static targets = ["queryInput", "clearInput", "popoverContainer"];

  declare algoliaAppIdValue?: string;
  declare algoliaPublicApiKeyValue?: string;
  declare algoliaIndexNameValue?: string;
  declare algoliaIndexTypeValue?: string;
  declare initialQueryValue?: string;
  declare storeIdsValue?: number[];
  declare excludeAgeRestrictedStoresValue?: boolean;
  declare imageBaseUrlValue: string;
  declare customerUserTokenValue?: string;
  declare customerAuthenticatedUserTokenValue?: string;
  declare queryInputTargets: HTMLInputElement[];
  declare clearInputTargets: HTMLElement[];
  declare popoverContainerTarget: HTMLElement;

  connect() {
    if (!this.algoliaAppIdValue || !this.algoliaPublicApiKeyValue) {
      throw "Missing autocomplete configuration";
    }

    if (this.queryInputTargets.length == 0) {
      throw "Missing autocomplete input target";
    }

    const resultsPanelElement = this.element.querySelector("#autocomplete-results");

    if (resultsPanelElement === null) {
      throw "Cannot find element target with #autocomplete-results"
    }

    const searchClient = algoliasearch(this.algoliaAppIdValue, this.algoliaPublicApiKeyValue, {
      baseHeaders: {
        'X-Algolia-UserToken': `${this.customerAuthenticatedUserTokenValue || this.customerUserTokenValue}`
      }
    });

    // Initialize insights client
    searchInsights("init", {
      appId: this.algoliaAppIdValue,
      apiKey: this.algoliaPublicApiKeyValue,
      userToken: this.customerUserTokenValue,
      authenticatedUserToken: this.customerAuthenticatedUserTokenValue,
    });

    const initialQuery = this.initialQueryValue || "";

    const root = createRoot(resultsPanelElement);

    root.render(this.buildAutocompleteWidget(searchClient, initialQuery));
  }

  buildAutocompleteWidget(searchClient: LiteClient, initialQuery: string) {
    if (!this.algoliaIndexNameValue) throw "Missing index name";
    if (!this.imageBaseUrlValue) throw "Missing base url";
    if (!this.algoliaIndexTypeValue) throw "Missing index type";

    if (this.algoliaIndexTypeValue == "stores") {
      const config = new StoresAutocompleteConfig(this.algoliaIndexNameValue, this.imageBaseUrlValue, this.storeIdsValue, this.excludeAgeRestrictedStoresValue)

        return <Autocomplete
          searchClient={searchClient}
          autocompleteConfig={config}
          inputElements={this.queryInputTargets}
          clearInputElements={this.clearInputTargets}
          popoverContainer={this.popoverContainerTarget}
          initialQuery={initialQuery}
        />
    } else {
      throw `Unrecognised index type: ${this.algoliaIndexTypeValue}`;
    }
  }
}
