import { Controller } from "@hotwired/stimulus";
import { MenuItem, OptionSet, Option } from "src/menu_item";

export default class extends Controller {
  static targets = ["modal", "title", "description", "descriptionToggle", "descriptionInputToggle",
    "image", "backgroundImage", "mealPreferences", "quantity", "addToCartText", "addToCartSubtotal",
    "optionSets", "addToCartButton", "price", "ready", "loading", "error", "ageGate", "errorHeader",
    "errorContent", "errorButtonText"
  ];

  private menuItem : MenuItem | null = null;
  private error : boolean = false;

  declare ageVerifiedStatus : boolean;
  declare modalTarget : HTMLElement;
  declare titleTarget : HTMLElement;
  declare descriptionTarget : HTMLElement;
  declare descriptionToggleTargets : HTMLElement[];
  declare descriptionInputToggleTargets : HTMLInputElement[];
  declare imageTarget : HTMLElement;
  declare backgroundImageTarget : HTMLElement;
  declare priceTarget : HTMLElement;
  declare mealPreferencesTarget : HTMLTextAreaElement;
  declare hasMealPreferencesTarget : boolean;
  declare quantityTarget : HTMLInputElement;
  declare addToCartTextTarget : HTMLElement;
  declare addToCartSubtotalTarget : HTMLElement;
  declare optionSetsTarget : HTMLElement;
  declare addToCartButtonTarget : HTMLElement;
  declare ageGateTargets : HTMLElement[];
  declare readyTargets : HTMLElement[];
  declare loadingTargets : HTMLElement[];
  declare errorTargets : HTMLElement[];
  declare errorHeaderTarget : HTMLHeadElement;
  declare errorContentTarget: HTMLParagraphElement;
  declare errorButtonTextTarget : HTMLSpanElement;

  connect() : void {
    this.ageVerifiedStatus = this.data.get('age-verified-status') === 'true';
  }

  open(ev : CustomEvent) {
    const menuItemData = ev.detail;
    this.menuItem = null;
    this.error = false;
    this.optionSetsTarget.innerHTML = ""

    // Let's get the modal loading and open ASAP
    window.history.pushState({}, "", "#item");

    window.setTimeout(() => {
      if (this.menuItem === null && this.error === false) {
        this.showLoadingModal();
      }
    }, 500);

    this.fetchItemDetails(menuItemData.url)
  }

  close() {
    window.history.back();
    this.dismiss();
  }

  closeFromBack() {
    this.dismiss();
  }

  confirmAge() {
    const metaElement = document.querySelector("meta[name='csrf-token']") as HTMLMetaElement | null;
    const csrfToken = metaElement?.content || "";

    fetch('/age_verification', {
      method: "POST",
      credentials: "include",
      headers: { "X-CSRF-Token": csrfToken },
    }).then(resp => {
      if (resp.status === 204) {
        this.ageVerifiedStatus = true;
        this.showReadyModal();
      } else {
        this.showErrorModal();
      }
    }).catch(() => {
      this.showErrorModal();
    });
  }

  addToCart() {
    if (this.menuItem === null) {
      return;
    }

    this.addToCartButtonTarget.setAttribute("disabled", "true");

    if (this.hasMealPreferencesTarget) {
      this.menuItem.mealPreferences = this.mealPreferencesTarget.value;
    }

    const ev = new CustomEvent("addItemToCart", { bubbles: true, detail: this.menuItem });
    this.element.dispatchEvent(ev);
  }

  addItemFailed(ev: CustomEvent) {
    ev.detail;
    this.showErrorModal("Adding to cart failed", ev.detail, "Back");
  }

  populateModal() {
    const menuItem = this.menuItem!;
    this.titleTarget.innerText = menuItem.name;
    this.descriptionTarget.innerText = menuItem.description;
    this.imageTarget.style.backgroundImage = menuItem.image !== null ? `url('${menuItem.image}')` : '';
    this.backgroundImageTarget.style.backgroundImage = menuItem.image !== null ? `url('${menuItem.image}')` : '';
    this.priceTarget.innerText = this.basePriceFormatted();
    this.quantityTarget.value = menuItem.quantity.toString();
    this.addToCartSubtotalTarget.innerText = this.subtotalFormatted();

    this.populateOptionSets();

    const validatedOptionSets = this.validateOptionSets();
    if (validatedOptionSets instanceof OptionSet) {
      this.addToCartButtonTarget.setAttribute("disabled", "true");
      this.addToCartTextTarget.innerText = `Review required options`;
      return;
    }

    this.addToCartButtonTarget.removeAttribute("disabled");

    if (menuItem.quantity <= 1) {
      this.addToCartTextTarget.innerText = `Add item to cart`;
    } else {
      this.addToCartTextTarget.innerText = `Add ${menuItem.quantity} items to cart`;
    }
  }

  populateOptionSets() {
    if (!this.menuItem) {
      return;
    }

    const menuItem = this.menuItem;

    this.optionSetsTarget.innerHTML = "";

    menuItem.optionSets.sort((a, b) => {
      const aPosition = a.minimumSelections > 0 ? a.position - 1000 : a.position;
      const bPosition = b.minimumSelections > 0 ? b.position - 1000 : b.position;

      return aPosition - bPosition;
    }).forEach(optionSet => {
      const divider = document.createElement("div");
      divider.classList.add("divider");
      divider.classList.add("has-content");

      const header = document.createElement("div");
      header.classList.add("label");
      header.innerText = optionSet.name;

      const subtext = document.createElement("span");
      if (optionSet.isRadioSelect()) {
        subtext.innerText = ""
      } else {
        if (optionSet.minimumSelections < 1) {
          if (optionSet.maximumSelections == 1) {
            subtext.innerText = `Add on one of the following`;
          } else {
            subtext.innerText = `Choose up to ${optionSet.maximumSelections} options`;
          }
        } else {
          subtext.innerText = `Choose between ${optionSet.minimumSelections} and ${optionSet.maximumSelections} options`;
        }
      }

      subtext.classList.add("is-tiny-text");
      subtext.classList.add("is-aligned-right");

      divider.appendChild(header);
      divider.appendChild(subtext);

      if (optionSet.minimumSelections > 0) {
        const badge = document.createElement("div");
        badge.classList.add("badge");
        badge.classList.add("is-strong");
        badge.classList.add("space-ml-md");
        badge.innerText = "Required";
        divider.appendChild(badge);
      }

      this.optionSetsTarget.appendChild(divider);

      const optionSets = document.createElement("section");
      const optionSetContainer = document.createElement("div");
      optionSetContainer.classList.add("container");
      optionSets.setAttribute("data-cy", "menu-item-option-set");

      optionSets.appendChild(optionSetContainer);
      this.buildOptionsForOptionSet(optionSet, optionSetContainer);
      this.optionSetsTarget.appendChild(optionSets);
    });
  }

  validateOptionSets() {
    if (this.menuItem === null) {
      return false;
    }

    for (const os of this.menuItem.optionSets) {
      if (os.isValid() === false) {
        return os;
      }
    }

    return true;
  }

  buildOptionsForOptionSet(optionSet: OptionSet, optionSetContainer: HTMLElement) {
    const sortedItems = optionSet.options;
    sortedItems.sort((a, b) => a.position - b.position);

    sortedItems.forEach(option => {
      const optionLineItem = document.createElement("div");
      const optionContainer = document.createElement("div");

      const optionElement = document.createElement("input");
      const optionLabel = document.createElement("label");
      const optionLabelText = document.createElement("span");
      const optionLabelSurcharge = document.createElement("span");

      optionLineItem.classList.add("line-item");
      optionLabelSurcharge.classList.add("additional-cost");
      optionElement.id = `${optionSet.name}-${option.name}-${option.id}`;
      optionLabel.htmlFor = `${optionSet.name}-${option.name}-${option.id}`;

      if (optionSet.isRadioSelect()) {
        optionElement.type = "radio";
        optionElement.name = `os-${optionSet.name}-${optionSet.id}`;
        optionContainer.classList.add("radio");
      } else {
        optionElement.type = "checkbox";
        optionContainer.classList.add("checkbox");
      }

      optionLabelText.innerText = option.name;
      if (Math.abs(option.surcharge) > 0.01) {
        optionLabelSurcharge.innerText = option.surchargeFormatted();

        if (option.surcharge < 0) {
          optionLabelSurcharge.classList.add("negative");
        } else {
          optionLabelSurcharge.classList.add("positive");
        }
      }

      optionElement.checked = option.selected;

      optionElement.setAttribute("data-component-id", option.id.toString());
      optionElement.addEventListener("change", this.onSelection.bind(this, optionSet, option));

      optionLineItem.appendChild(optionContainer);
      optionContainer.appendChild(optionElement);
      optionContainer.appendChild(optionLabel);

      optionLabel.appendChild(optionLabelText);
      optionLabel.appendChild(optionLabelSurcharge);

      optionLabel.setAttribute("data-cy", "menu-item-option");

      optionSetContainer.appendChild(optionLineItem);
    });
  }

  onSelection(optionSet: OptionSet, option: Option, event: Event) {
    if (!event.target) {
      return;
    }

    const eventTarget : HTMLInputElement = event.target as HTMLInputElement;

    if (eventTarget.checked) {
      optionSet.select(option);
    } else {
      optionSet.deselect(option);
    }

    this.populateModal();
  }

  setTargets(targets: HTMLElement[], newState: TargetState) {
    targets.forEach(el => {
      if (newState === TargetState.hidden) {
        el.classList.add("is-completely-hidden")
      } else {
        el.classList.remove("is-completely-hidden");
      }
    });
  }

  readyModalTargets() {
    this.setTargets(this.readyTargets, TargetState.hidden);
    this.setTargets(this.errorTargets, TargetState.hidden);
    this.setTargets(this.loadingTargets, TargetState.hidden);
    this.setTargets(this.ageGateTargets, TargetState.hidden);
  }

  showLoadingModal() {
    this.readyModalTargets();
    this.setTargets(this.loadingTargets, TargetState.visible);

    this.showModal();
  }

  showReadyModal() {
    if (this.ageVerifiedStatus || !this.menuItem?.ageRestricted) {
      this.readyModalTargets();
      this.setTargets(this.readyTargets, TargetState.visible);

      this.showModal();
    } else {
      this.showAgeGateModal();
    }

    this.updateDescriptionToggle();
  }

  showErrorModal(
    headertext: string = "Connection lost",
    content: string = "There seems to be some trouble with the network - check your internet connection and try again in a minute.",
    buttonText: string = "Retry"
  ) {
    this.errorHeaderTarget.innerHTML = headertext;
    this.errorContentTarget.innerHTML = content;
    this.errorButtonTextTarget.innerHTML = buttonText;

    this.readyModalTargets();
    this.setTargets(this.errorTargets, TargetState.visible);

    this.showModal();
  }

  showAgeGateModal() {
    this.readyModalTargets();
    this.setTargets(this.ageGateTargets, TargetState.visible);

    this.showModal();
  }

  showModal() {
    this.modalTarget.classList.add("is-active");
    const card = this.modalTarget.querySelector(".card");
    if (card !== null) { card.scrollTop = 0; }
  }

  basePriceFormatted(): string {
    const formatter = new Intl.NumberFormat("en-NZ", { style: "currency", currency: "NZD" });
    return formatter.format(this.menuItem!.price);
  }

  subtotalFormatted(): string {
    const formatter = new Intl.NumberFormat("en-NZ", { style: "currency", currency: "NZD" });
    return formatter.format(this.menuItem!.subtotal());
  }

  preventDismiss(event : Event) {
    event.stopPropagation();
  }

  dismiss() {
    this.modalTarget.classList.remove("is-active");
  }

  dismissWithEscape(event: KeyboardEvent) {
    if (event.key == "Escape") {
      this.dismiss();
    }
  }

  changeQuantity() {
    let resolvedQuantity = parseInt(this.quantityTarget.value);

    if (isNaN(resolvedQuantity)) {
      resolvedQuantity = 1;
      this.quantityTarget.value = "1";
    }

    this.menuItem!.quantity = resolvedQuantity;
    this.populateModal();
  }

  incrementQuantity() {
    this.quantityTarget.stepUp();
    this.menuItem!.quantity = parseInt(this.quantityTarget.value);
    this.populateModal();
  }

  decrementQuantity() {
    this.quantityTarget.stepDown();
    this.menuItem!.quantity = parseInt(this.quantityTarget.value);
    this.populateModal();
  }

  fetchItemDetails(url: RequestInfo) {
    fetch(url, {
      credentials: "same-origin",
      headers: {
        'Content-Type': 'application/json',
        Mode: 'cors',
      },
    }).then(rawResponse => rawResponse.json()).then(result => {
      const menuItem = MenuItem.fromObject(result);
      this.menuItem = menuItem;

      this.menuItem.reset();

      if (this.hasMealPreferencesTarget) {
        this.mealPreferencesTarget.value = "";
      }

      this.populateModal();
      this.showReadyModal();
    }).catch((e) => {
      this.error = true;
      this.menuItem = null;
      this.showErrorModal();

      if (e.message == "Failed to fetch" || e.message == "NetworkError when attempting to fetch resource.") {
        // We don't need to know about 'routine' network failures
      } else {
        throw e;
      }

    });
  }

  updateDescriptionToggle() {
    if (this.descriptionToggleTargets) {
      this.descriptionInputToggleTargets.forEach(element => {
        element.checked = false;
      });

      if (this.descriptionTarget.scrollHeight > this.descriptionTarget.clientHeight) {
        this.descriptionToggleTargets.forEach(element => {
          element.classList.remove("is-completely-hidden");
        });
      } else {
        this.descriptionToggleTargets.forEach(element => {
          element.classList.add("is-completely-hidden");
        });
      }
    }
  }

  retry() {
    window.location.reload();
  }
}
enum TargetState {
  hidden,
    visible
};


